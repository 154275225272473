import floatthead from 'floatthead';
import Alpine from 'alpinejs'
import persist from '@alpinejs/persist'
import sort from '@alpinejs/sort'
import {computePosition, autoUpdate, shift} from '@floating-ui/dom';

import "/ui/js/Components/DashboardBundle/recordedTime.js";
import "/ui/js/Components/ReportBundle/projectPriorityReport.js";
import "/ui/js/Components/ReportBundle/budgetReport.js";
import "/ui/js/Components/unified-help/unifiedHelpComponent.js";
import "/ui/js/Components/unified-help/articleComponent.js";
import "/ui/js/Components/unified-help/searchComponent.js";

// tiny mce
import "/ui/js/Components/TinyMCE/index.js";

window.computePosition = computePosition;
window.autoUpdate = autoUpdate;
window.shift = shift;

window.Alpine = Alpine;
Alpine.plugin(persist)
Alpine.plugin(sort)

jQuery(document).ready(function() {
  Alpine.start();
});

